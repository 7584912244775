import React from "react";
import { Link } from "gatsby-plugin-intl";
import { HighlightedText } from "../../system42";

import Layout from "../../layouts/layout";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HeaderCompare } from "../../components/HeaderCompare";
import { HtmlContent } from "../../components/HtmlContent";
import { Testimonials } from "../../components/Testimonials";
import {
  testimonialThomas,
  testimonialJoshua,
  useLocalCurrencySymbol,
  pricePayAsYouGoTest,
  advertedPoolSize,
  priceStarterMonthly,
  priceAgencyMonthly,
  testimonialPaul,
} from "../../helpers";
import * as helperStyles from "../helpers.module.css";
import * as styles from "./compare.module.css";

import imageDashboardSessions from "../../images/compareDashboardSessions.jpg";

const ContentPage = () => {
  const currencySymbol = useLocalCurrencySymbol("$");
  return (
    <Layout
      title={"TryMyUi vs. Userbrain"}
      metaDescription={
        "Want to compare TryMyUi and Userbrain? We get it; everyone wants to explore their options."
      }
    >
      <article>
        <HeaderCompare
          title={
            <>
              <span className={helperStyles.h1Serif}>TryMyUi vs.</span>{" "}
              Userbrain
            </>
          }
          content={
            "Looking for a UX testing tool and comparing what's out there? We get it; everyone wants to explore their options. So we would like to make things a little easier for you. Here's a list of 8 differences between TryMyUi and Userbrain:"
          }
          imageSrc={imageDashboardSessions}
          imageAlt={
            "Userbrain dashboard showing a list of remote user testing videos"
          }
        />
        <div className={helperStyles.container}>
          <HtmlContent className={styles.htmlContent}>
            <h2>1. Simple sign-up vs. too much data</h2>
            <p>
              It takes a fair few minutes to sign up at TryMyUi. Whereas, with
              Userbrain, it's more like a few seconds. We won't ask you for any
              excessive personal details. Once you've set up your email and
              password, you're good to go.
            </p>

            <h2>2. Userbrain offers better value for money</h2>

            <p>
              Userbrain offers{" "}
              <Link to="/pricing/">a better selection of plans</Link>, starting
              from {currencySymbol}
              {priceStarterMonthly}/month. So whether your user testing budget
              is $100 or $100,000, Userbrain has you covered. In contrast,
              TryMyUi's most affordable subscription is significantly more
              expensive than Userbrain's, at $333/month.
            </p>
            <p>
              Remember, Userbrain also offers the option to pay annually. With
              an annual Userbrain subscription, you can save up to{" "}
              {currencySymbol}
              {priceAgencyMonthly * 2} and enjoy 2 months of user testing for
              free.
            </p>
            <p>
              Pay-As-You-Go user tests are also available at Userbrain, priced
              at just {currencySymbol}
              {pricePayAsYouGoTest} per test. Save up to 33% when you purchase a
              pack of 30 credits or more. On the other hand, TryMyUi only offers
              a Pay-As-You-Go option if you're already subscribed to one of
              their plans.
            </p>

            <h2>3. Credit rollover vs. losing out</h2>

            <p>
              TryMyUi and Userbrain both offer user testing on a credit-based
              system. One credit = 1 UX test. With a subscription plan, you get
              a certain number of credits assigned to your account every month.
              All good so far.
            </p>
            <p>
              But, since Userbrain was founded by a team of UX Designers, we
              know that things can change; you might not need to test one month,
              but you'll run a ton of tests the next! That's why we decided that
              unused credits will roll over.
            </p>

            <h2>4. Elegant simplicity vs. coarse complexity</h2>
            <p>
              Setting up a user test with TryMyUi is complex and, honestly, a
              little draining. You have to answer a boatload of unnecessary
              questions and tick boxes - before you've figured out what they
              even mean.
            </p>
            <p>
              At Userbrain, we are all about keeping things simple and
              efficient, so you can get down to testing. We've removed all the
              red tape so you can focus on setting up brilliant task scenarios
              and measuring the responses. No fluff, no fuss.
            </p>

            <h2>5. Ready-made test scenario templates</h2>

            <p>
              We know that coming up with a test scenario can be a bit
              challenging, especially for the first time. That's why we decided
              to integrate generic test instructions. Userbrain's test scenario
              templates will help you understand what you could or should ask
              your testers. This makes setting up your first tests easier and
              helps you get into the swing of things straight away.
            </p>

            <h2>6. Intuitive design vs. confusing UI</h2>

            <p>
              At Userbrain, we do our best to not only talk the talk but also
              walk the walk. We were founded by four UX Designers who wanted to
              have this tool in our lives. We recognized a need and built the
              product to serve that need.
            </p>

            <p>
              We don't just want to help our customers improve their UX and UI -
              we want to provide the best possible experience for you, too!
              Userbrain has a clean, modern interface optimized for efficient
              user testing, saving you time, money, and frustration. On the
              other hand, TryMyUi could have set a better example in this
              regard.
            </p>

            <h2>7. Premium user testing features as standard</h2>

            <p>
              TryMyUi unlocks a selection of features depending on which plan
              you sign up for. While at Userbrain, you gain access to every tool
              needed to perform brilliant unmoderated remote user tests. Not to
              mention access to{" "}
              <Link to="/features/tester-pool/">
                our global pool of {advertedPoolSize} testers
              </Link>{" "}
              - no matter which subscription you choose.
            </p>

            <h2>8. Automated user testing vs. manual user testing</h2>

            <p>
              To figure out what's working or not, you should conduct UX tests
              more than once - ideally at regular intervals. With Userbrain, you
              can subscribe & save to test regularly and turn it into a habit -
              rather than a one-off thing you need to check up on.
            </p>

            <p>Here are just a few reasons to make the switch…</p>
          </HtmlContent>
        </div>
        <div className={helperStyles.container}>
          <Testimonials
            testimonials={[
              testimonialJoshua,
              testimonialThomas,
              testimonialPaul,
            ]}
          />
          <GetStartedBox
            className={helperStyles.spacingLg}
            title={
              <>
                Ready for your first{" "}
                <HighlightedText cursor>user test?</HighlightedText>
              </>
            }
            textBottom={
              "Get set up in minutes. Your first test is ready in a few hours."
            }
          />
        </div>
      </article>
    </Layout>
  );
};

export default ContentPage;
